<template>
  <allergies-template
    :allergy-types="allergyTypes"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import AllergiesTemplate from '@/modules/questionnaire/components/steps/common/medical-background/allergies/AllergiesTemplate';

import { makeStep, resetStoreValues } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { ALLERGY, NONE } from '@/modules/questionnaire/api/constants';

const foodAllergiesFields = ['foodAllergies', 'otherFoodAllergies'];
const medicationAllergiesFields = ['medicationAllergies', 'otherMedicationAllergies'];
const environmentalAllergiesFields = ['environmentalAllergies', 'otherEnvironmentalAllergies'];
const substanceAllergiesFields = ['substanceAllergies', 'otherSubstanceAllergies'];

const fieldsToReset = [
  ...foodAllergiesFields,
  ...medicationAllergiesFields,
  ...environmentalAllergiesFields,
  ...substanceAllergiesFields
];

const { requiredArrayField, field } = fieldBuilder;

const FIELDS = [...fieldsToReset.map(field), requiredArrayField('allergyTypes')];

export default {
  name: 'Allergies',
  components: {
    AllergiesTemplate
  },
  mixins: [makeStep(FIELDS), resetStoreValues],
  watch: {
    allergyTypes(newValue, oldValue) {
      if (newValue.includes(NONE) && !oldValue.includes(NONE)) {
        this.resetStoreFieldsByName(fieldsToReset);

        this.showNextStep();
      }

      if (!newValue.includes(ALLERGY.FOOD)) {
        this.clearFoodAllergies();
      }

      if (!newValue.includes(ALLERGY.MEDICATION)) {
        this.clearMedicationAllergies();
      }

      if (!newValue.includes(ALLERGY.ENVIRONMENTAL)) {
        this.clearEnvironmentalAllergies();
      }

      if (!newValue.includes(ALLERGY.SUBSTANCE)) {
        this.clearSubstanceAllergies();
      }
    }
  },
  methods: {
    clearFoodAllergies() {
      this.resetStoreFieldsByName(foodAllergiesFields);
    },
    clearMedicationAllergies() {
      this.resetStoreFieldsByName(medicationAllergiesFields);
    },
    clearEnvironmentalAllergies() {
      this.resetStoreFieldsByName(environmentalAllergiesFields);
    },
    clearSubstanceAllergies() {
      this.resetStoreFieldsByName(substanceAllergiesFields);
    }
  }
};
</script>
